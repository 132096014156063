import { SrcSetConfig } from 'types/SrcSetConfig';
import resolveImageUrl, { getExtension, ResolveConfig } from 'utils/resolveImageUrl';

import { Dimensions } from './BetterImage.types';

export const resolveSrcSetConfig = (resolveConfig: ResolveConfig, set: SrcSetConfig): string =>
  [
    `${resolveImageUrl({ ...resolveConfig, preset: set.preset })}`,
    set.preset !== 'svg' ? `${resolveImageUrl({ ...resolveConfig, preset: set.preset }, { suffix: '_2x' })} 2x` : '',
    set.withAdditionalDimensions
      ? [
          `${resolveImageUrl({ ...resolveConfig, preset: set.preset }, { suffix: '_3x' })} 3x`,
          `${resolveImageUrl({ ...resolveConfig, preset: set.preset }, { suffix: '_4x' })} 4x`,
        ]
      : '',
  ]
    .flat()
    .filter(Boolean)
    .join(', ');

export const getSrcSet = (resolveConfig: ResolveConfig, hasIncreasedDensity?: boolean): string => {
  const result = [resolveImageUrl(resolveConfig)];

  if (resolveConfig.preset !== 'svg' && hasIncreasedDensity) {
    result.push(`${resolveImageUrl(resolveConfig, { suffix: '_2x' })} 2x`);
  }

  return result.join(', ');
};

export const getDimension = (dimension: number | string): string =>
  typeof dimension === 'number' ? `${dimension}px` : dimension;

const calculateFitDimensions = (
  config: Pick<ResolveConfig, 'width' | 'height'>,
  width: number,
  height: number
): Dimensions => {
  if (!config.width || !config.height) {
    return { width, height };
  }

  const widthFactor = config.width / width;
  const possibleHeight = config.height / widthFactor;
  if (possibleHeight <= height) {
    return { width, height: Math.round(possibleHeight) };
  }
  const heightFactor = config.height / height;
  const possibleWidth = config.width / heightFactor;
  return { width: Math.round(possibleWidth), height };
};

export const getOriginalDimensions = ({ preset, width, height }: ResolveConfig): Dimensions => {
  switch (preset) {
    case 'p_main_theone':
      return { height: 350, width: 350 };
    case 'p_main':
    case 'p_main_2x':
      return calculateFitDimensions({ width, height }, 576, 385);
    case 'p_full_480':
    case 'p_full_960':
    case 'p_full_1440':
    case 'p_full_1440_jpg':
    case 'p_full_1920':
    case 'p_full_3840':
      return calculateFitDimensions({ width, height }, 480, 240);
    case 'p_thumb_1':
    case 'p_thumb_1_2x':
      return calculateFitDimensions({ width, height }, 220, 146);
    case 'p_thumb_2':
    case 'p_thumb_2_2x':
      return calculateFitDimensions({ width, height }, 130, 100);
    case 'p_thumb_3':
    case 'p_thumb_3_2x':
      return calculateFitDimensions({ width, height }, 60, 51);
    case 'p_thumb_4':
    case 'p_thumb_4_2x':
      return calculateFitDimensions({ width, height }, 48, 41);
    case 'p_thumb_5':
    case 'p_thumb_5_2x':
      return { width: 32, height: 28 };
    case 'p_thumb_6':
    case 'p_thumb_6_2x':
      return calculateFitDimensions({ width, height }, 80, 54);
    case 'testimonial':
    case 'testimonial_2x':
      return { width: 138, height: 138 };
    case 'customer_logo':
    case 'customer_logo_2x':
      return { height: 120, width: 120 };
    case 'footer_logo':
    case 'footer_logo_2x':
      return calculateFitDimensions({ width, height }, 120, 120);
    case 'prize':
    case 'prize_2x':
      return { width, height };
    case 'main_menu_icon':
    case 'main_menu_icon_2x':
    case 'category_icon':
    case 'category_icon_2x':
      return { width: 24, height: 24 };
    case 'category_banner':
    case 'category_banner_2x':
      return { width: Math.min(width || 347, 347), height: Math.min(height || 264, 264) };
    case 'avatar':
      return { width: 80, height: 80 };
    default:
      return { width, height };
  }
};

export const getStringifiedImage = ({
  className,
  title = '',
  alt = '',
  src,
  width = 0,
  height = 0,
}: {
  className: string;
  title?: string;
  alt?: string;
  src: string;
  width?: number;
  height?: number;
}) =>
  `<img class="${className}" ${
    title ? `title="${title}" ` : ''
  }alt="${alt}" src="${src}" width="${width}" height="${height}">`;

export const getMimeType = ({ preset }: ResolveConfig) => {
  const extension = getExtension(preset);
  return `image/${extension === 'jpg' ? 'jpeg' : extension}`;
};
